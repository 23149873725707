
export const HEADER_HEIGHT = 64

export const constantsToStyle = () => ({
    '--header-height': `${HEADER_HEIGHT}px`
})

export const DEMO_MESSAGE = `Hi! 👋

I would like to request a demo of TagHub. 🚀

Please contact me on this e-mail.

Thanks 🙂`

export const ENTERPRISE_MESSAGE = `Hi! 👋

I would like more info on TagHub Enterprise. 🚀

Please contact me on this e-mail.

Thanks 🙂`

export const SIGNUP_MESSAGE = `Hi! 👋

I would like to sign-up to TagHub. 📝

Please contact me to this e-mail.

Thanks 🙂`

export const TERMS_URL = 'https://docs.google.com/document/d/1Hsfj7wekME3kPCu4aP69fHDtx6Z6zUivT2s3sfrbFH8/edit?usp=sharing'

export const PRIVACY_URL = 'https://docs.google.com/document/d/1ne4ZUTRGiW8V8rcnnRsnjy76izaTc38bKLErbQeUbqo/edit?usp=sharing'

export const STATUS_URL = 'https://status.taghub.net'

export const HOME_PATH = '/login'

export const TAGHUB_HOME_URL = 'https://home.taghub.net/'