import monitoring from '@taghub/taghub-monitoring'
import pkg from '../package.json'

const setupMonitoring = () => {
  monitoring.init({
    dsn: 'https://d1bca48b4e3648d9ab7155b5d4463824@o4504536130060288.ingest.sentry.io/4504579120693255',
    version: pkg.version
  })
}

setupMonitoring()